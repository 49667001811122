const sirportlyCloudSignup = {

  // Check the authentication details provided and update
  // the form as appropriate.
  checkAuth(form){
    $.ajax({
      url: '/start/auth_check',
      data: form.serialize(),
      type: 'GET',
      dataType: 'json',
      success(data){
        console.log(data);
        if (data.email_check.status === 'available') {
          // E-mail address is available
          $('.jsSignupForm__choosePasswordLabel', form).show();
          $('.jsSignupForm__enterPasswordLabel', form).hide();
          $('.jsSignupForm__preExistingAccountMessage', form).hide('fast');
        } else {
          // E-Mail address is currently registered to another user
          $('.jsSignupForm__choosePasswordLabel', form).hide();
          $('.jsSignupForm__enterPasswordLabel', form).show();
          $('.jsSignupForm__preExistingAccountMessage', form).show('fast');
          $('.jsSignupForm__preExistingAccountMessage b', form).text(data.email_check.email);

          if (data.authentication) {
            if (data.authentication.status === 'valid') {
              // Password is correct
              if ($('input[name=first_name], input[name=last_name]').is(':focus')) {
                $('input[name=account_name]').focus();
              }
              $('input[name=first_name]', form).val(data.authentication.first_name).prop('disabled', true);
              $('input[name=last_name]', form).val(data.authentication.last_name).prop('disabled', true);
              $('input[name=password]', form).removeClass('comFormField-failure').addClass('comFormField-success');
            } else {
              // Password is not correct
              $('input[name=first_name]', form).prop('disabled', false);
              $('input[name=last_name]', form).prop('disabled', false);
              $('input[name=password]', form).removeClass('comFormField-success').addClass('comFormField-failure');
            }
          } else {
            // No password provided
            $('input[name=password]', form).removeClass('comFormField-success').removeClass('comFormField-failure');
          }
        }
      }
    });
  }
};

$(function() {
  const signupForm = $('form.jsSignupForm');
  if (signupForm.length) {

    // The timer for checking auth
    let formAuthCheckTimer = null;

    if ($("input[name=email_address]").val().length > 0) {
      sirportlyCloudSignup.checkAuth(signupForm);
    }

    // After half a second, automatically check credentials
    $("input[data-context=credential]", signupForm).keyup(function(e){
      if ((e.which !== 9) && (e.which !== 16) && (e.which !== 91) && (e.which !== 91)) {
        clearTimeout(formAuthCheckTimer);
        formAuthCheckTimer = setTimeout(() => sirportlyCloudSignup.checkAuth(signupForm)
          , 500);
      }
    });

    // Disable on submit
    signupForm.on('submit', () => $('input[type=submit]', signupForm).attr('disabled', true).addClass('comButton-lightGreyBlue'));

    // Reset password link
    $('[data-behavior=resetPassword]').on('click', () => $.ajax({
      url: '/start/reset_password',
      data: signupForm.serialize(),
      type: 'POST',
      dataType: 'json',
      success(data){
        if (data.address) {
          alert(`A new password has been sent to ${data.address}`);
        } else {
          alert("We couldn't send a password to you. Please contact support if you continue to have issues logging in.");
        }
      }
    }));
  }
});
