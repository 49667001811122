if (!window.Nifty) { window.Nifty = {}; }
window.Nifty.Dialog = {

  // The numerical ID to start showing dialogs from
  startingID: 1,

  // A callback reference which is run on content set if set.
  onSetContent: null,

  // Stores all behaviors
  behaviors: {},

  // Open a new dialog which will accept a number of possible options.
  //
  //   id         => the ID to assign to this dialog (prefixed with 'niftyDialog-' )
  //
  //   url        => open a dialog containing the HTML at the given URL. When
  //                 displaying using a URL, the dialog will open immediately
  //                 and containing a spinner until the data is loaded.
  //
  //   html       => a string of HTML which contains the HTML which should be
  //                 displayed in the dialog.
  //
  //   afterLoad  => a callback to execute after the dialog has been loaded.
  //
  //   width      => the width of the dialog (in px, defaults to CSS-provided)
  //
  //   offset     => specifies a vertical offset (in px)
  //
  //   stack      => adds an offset if this is not the first open dialog
  //
  //   class      => the CSS class to assign to this dialog
  //
  //   behavior   => the name of a behavior set to be invoked on dialog open/close.
  //                 Behaviors can be setup using the Nifty.Dialog.addBehavior method.
  //                 Valid behaviors: beforeLoad, onLoad, onSetContent, onClose
  //
  open(options){
    // set a dialog ID for this dialog
    if (options == null) { options = {}; }
    const dialogsOpen = $('div.niftyDialog').length;
    const dialogID = dialogsOpen === 0 ? this.startingID : (dialogsOpen * 10) + this.startingID;

    if (options.id == null) { options.id = dialogID; }

    // create a template and assign the ID
    const dialogTemplate = $(`<div class='niftyDialog ${options.class}' id='niftyDialog-${options.id}'></div>`);
    dialogTemplate.data('dialogID', dialogID);

    // insert the dialog into the page
    const insertedDialog = dialogTemplate.appendTo($('body'));
    insertedDialog.css('z-index', 2000 + dialogID);

    // set the content on the dialog
    insertedDialog.data('options', options);

    let overlayClass = '';
    if (dialogID > 1) { overlayClass = 'invisible'; }
    const theOverlay = $(`<div class='niftyOverlay ${overlayClass}'></div>`).insertBefore(insertedDialog).css('z-index', (2000 + dialogID) - 1);
    theOverlay.fadeIn('fast');

    // if we have a width, set the width for the dialog
    if (options.width) {
      insertedDialog.css('width', `${options.width}px`);
      insertedDialog.css('margin-left', `-${options.width / 2}px`);
    }

    if (options.offset) {
      insertedDialog.css('margin-top', `${options.offset}px`);
    }

    if (options.stack) {
      const x = parseInt(insertedDialog.css('margin-left'), 10) + (dialogsOpen * 20);
      const y = parseInt(insertedDialog.css('margin-top'), 10) + (dialogsOpen * 30);
      insertedDialog.css({
        'margin-left': `${x}px`,
        'margin-top': `${y}px`
      });
    }

    // Set the closing action for the inserted dialog to close dialog
    // and fade out the appropriate overlay
    insertedDialog.data('closeAction', () => {
      let behavior;
      if (options.onClose) { options.onClose.call(null, insertedDialog, options); }
      if ((options.behavior) && (behavior = this.behaviors[options.behavior])) {
        if (behavior.onClose) { behavior.onClose.call(null, insertedDialog, options); }
      }
      insertedDialog.fadeOut('fast', () => insertedDialog.remove());
      theOverlay.fadeOut('fast', () => theOverlay.remove());
    });

    // Set that clicking on the dialog's overlay will close the dialog
    theOverlay.on('click', () => insertedDialog.data('closeAction').call());

    // load in the content
    if (options.url) {
      // if loading from a URL, do this
      let behavior;
      insertedDialog.addClass('ajax');
      insertedDialog.addClass('loading');
      if ((options.behavior) && (behavior = this.behaviors[options.behavior])) {
        if (behavior.beforeLoad) { behavior.beforeLoad.call(null, insertedDialog, options); }
      }

      $.ajax({
        url: options.url,
        success: data => this.displayDialog(insertedDialog, data)
      });

    } else if (options.html) {
      this.displayDialog(insertedDialog, options.html);

    } else {
      // anything else won't work
      console.log("Dialog could not be displayed. Invalid options passed.");
      console.log(options);
      return false;
    }
  },

  // Add a behaviour callback which will be executed
  addBehavior(options){
    if (options.name) {
      this.behaviors[options.name] = options;
      return true;
    } else {
      console.log("Must pass a 'name' option to the addBehavior method.");
      return false;
    }
  },

  // Complete the opening of a dialog with the given HTML
  displayDialog(dialog, content){
    let behavior;
    dialog.html(content);
    dialog.fadeIn('fast');
    dialog.removeClass('loading');
    const options = dialog.data('options');
    if ((options.behavior) && (behavior = this.behaviors[options.behavior])) {
      if (behavior.onLoad) { behavior.onLoad.call(null, dialog, options); }
    }
    if (options.afterLoad) { options.afterLoad.call(null, dialog); }
    if (this.onSetContent) { this.onSetContent(null, dialog); }
  },

  // This method will replace the contents of the nearest dialog (or the one with the
  // given ID if one is given).
  setContent(content, id = null){
    const dialog = id === null ? $('div.niftyDialog:last') : $(`div.niftyDialog#niftyDialog-${id}`);
    if (dialog.length) {
      let behavior;
      dialog.html(content);
      const options = dialog.data('options');
      if ((options.behavior) && (behavior = this.behaviors[options.behavior])) {
        if (behavior.onSetContent) { behavior.onSetContent.call(null, dialog, options); }
      }
      if (this.onSetContent) { this.onSetContent(null, dialog); }
    }
  },

  // This method will refectch the contents of the nearest dialog (or the one with the
  // given ID if one is given).
  reloadContent(id = null){
    const dialog = id === null ? $('div.niftyDialog:last') : $(`div.niftyDialog#niftyDialog-${id}`);
    const options = dialog.data('options');
    if (options.url) {
      let behavior;
      if ((options.behavior) && (behavior = this.behaviors[options.behavior])) {
        if (behavior.beforeLoad) { behavior.beforeLoad.call(null, dialog, options); }
      }
      $.ajax({
        url: options.url,
        success: data => this.setContent(data, id)
      });
    }
  },

  // Create a new overlay
  createOverlay(options){
    const overlay = $("<div class='niftyOverlay invisible'></div>");
    overlay.insertBefore(options.behind);
    overlay.css("z-index", options.behind.css('z-index') - 1);
    overlay.on('click', function() {
      options.close.call(overlay);
      overlay.fadeOut('fast', () => overlay.remove());
    });
    overlay.fadeIn('fast');
  },

  // Closes the top dialgo in the dialog stack
  closeTopDialog() {
    if ($('div.niftyDialog').length) {
      $('div.niftyDialog:last').data('closeAction').call();
    }
  }
};

