require("jquery");
const RailsUJS = require("@rails/ujs");
RailsUJS.start();

require("../src/nifty-dialog");
require("../src/signup");
require("../src/demo");

$(function() {
  //
  // All external links should open in a new window
  //
  $("a[href^='http://']").attr("target","_blank");
  $("a[href^='https://']").attr("target","_blank");

  //
  // Focus any fields with the jsFocus property
  //
  $('.jsFocus').focus();

  //
  // Opens a popup dialog with an overlay behind.
  //
  // Requires:
  //
  //     * data[element] - which should contain the name of the element which
  //                       should be opened/closed as appropriate.
  //
  $('a[data-behavior=openPopup]').on('click', function() {
    const elementToOpen = $(`.${$(this).data('element')}`);
    elementToOpen.show();
    if (!$('.niftyOverlay').length) {
      Nifty.Dialog.createOverlay({
        behind: elementToOpen,
        close() { return elementToOpen.hide(); }
      });
    }
    return false;
  });

  const switchQuestionType = function(field){
    $('.jsQuestionForm__cloudDomain, .jsQuestionForm__invoiceNumber').hide();
    const value = field.val();
    if (value === 'support-access') {
      $('.jsQuestionForm__cloudDomain').show();
    } else if (value === 'billing') {
      $('.jsQuestionForm__invoiceNumber').show();
    }
  };

  $('[data-behavior=switchQuestionType]').on('change', function() { switchQuestionType($(this)); });
  const existing_field = $('[data-behavior=switchQuestionType]');
  if (existing_field.length) { switchQuestionType(existing_field); }

  $('[data-behavior=showAllReleaseNotes]').on('click', function() {
    $('.pageReleaseNotes__version').show();
    $(this).parents('.viewContentPage').hide();
    return false;
  });

  $('.js-toggle-menu').on('click', function(e) {
    e.preventDefault();
    const bodyElement = $('body');
    if (bodyElement.hasClass('is-nav-open')) {
      bodyElement.removeClass('is-nav-open');
    } else {
      bodyElement.addClass('is-nav-open');
    }
  });

  if ($('.comFeatureTour').length) {
    $('.js-tour-link').on('click', function(e) {
      e.preventDefault();

      const $link = $(this);

      const currentlySelectedItem = $('.js-tour-item.active');

      const featureNumber = $(this).data('feature');
      const featureItem = $(`.js-tour-item[data-feature='${featureNumber}']`);

      $('.js-tour-link').removeClass('active');
      $link.addClass('active');

      // Is something transitioning?
      if ($('.js-tour-link.is-transitioning').length) {
        // Clear the existing timer
        clearTimeout(window.featureTourTimer);

        // Hide all active items and animation classes
        $('.js-tour-item').removeClass('active is-animated fadeInup fadeOutUp');

        // Make the target item visible and apply animation classes
        featureItem.addClass('active is-animated fadeInUp');

        window.featureTourTimer = setTimeout(() =>
          // Remove the transitioning class from the link
          $link.removeClass('is-transitioning')
        , 500);
      } else {
        // Add transition state class to link.
        $link.addClass('active is-transitioning');

        // Apply appropriate animation classes
        currentlySelectedItem
          .removeClass('is-animated fadeInUp')
          .addClass('is-animated fadeOutUp');

        // Set a timer for the duration of the animation 0.5s
        window.featureTourTimer = setTimeout(
          function() {
            // Remove the transitioning class from the link
            $link.removeClass('is-transitioning');

            // Remove animation classes
            currentlySelectedItem.removeClass('active fadeOutUp');

            // Apply appropriate animation classes to the new item
            featureItem.addClass('active is-animated fadeInUp');
          }
          , 500);
      }
    });
  }
});
