$(function() {
  const $form = $('form.jsDemoForm');

  if (!$form.length) { return; }

  const $source = $('.jsDemoForm__source');
  const $sourceOther = $('.jsDemoForm__sourceOther');

  $source.on('change', function(event) {
    $sourceOther.toggleClass('hidden', event.target.value !== 'Other');
    $sourceOther.attr('required', !$sourceOther.hasClass('hidden'));
  });
});
